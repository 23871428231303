import { Form, Row, Col, Input, Select, Button, message } from "antd";
import React, { useState, useEffect } from "react";
import StripeImg from "../Assets/stripe.svg";
import { Country, State, City } from "country-state-city";
import Stripe from "stripe";
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
import Authentication from "./Authentication";
function Payment({ setScreen, params, data, discountResponse, discountCode }) {
  const [countryid, setCountryid] = useState("AU");
  const [stateid, setstateid] = useState(0);
  const [countriesList, setCountriesList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [expiryDate, setExpiryDate] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [value, setvalues] = useState({});
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  // const stripe1 = new Stripe(
  //   "pk_live_51M7436JIpExDxcMiOpkiYy3XXLiKqI62mGTytuR11qQJCD3t7YwbQDZnYqmvQHYa0pao0q8l8h5pTfwkNsqK3KWt001LSC1l2R"
  // );

  const stripe_key = process.env.REACT_APP_STRIPE_KEY;
  const stripe1 = new Stripe(stripe_key);

  const handleSubmit = async (value) => {
    setLoading(true);
    setvalues(value);
    if (cardNumber?.length >= 19 && expiryDate) {
      try {
        const response = await axios.post(
          "https://prod-api.cinchy.me/api/v1/subscription/payment-otp",
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${params?.token}`,
            },
          }
        );
        setOtpSent(true);
        setScreen("authentication");
        message.success("OTP successfully sent on registered email.");
        setLoading(false);
      } catch (error) {}
    } else {
      message.error("please fill card details.");
    }
  };

  const handlePayment = async (data) => {
    // const stripe = await loadStripe(
    //   "pk_live_51M7436JIpExDxcMiOpkiYy3XXLiKqI62mGTytuR11qQJCD3t7YwbQDZnYqmvQHYa0pao0q8l8h5pTfwkNsqK3KWt001LSC1l2R"
    // );

    try {
      const stripe = await loadStripe(stripe_key);
      let temp = value;
      temp = {
        ...temp,
        ["country"]: countriesList?.filter((e) => e?.isoCode === countryid),
        ["state"]: stateList[stateid],
        ["cardNumber"]: cardNumber,
        ["expiryDate"]: expiryDate,
      };
      const token = await stripe1.paymentMethods.create({
        type: "card",
        billing_details: {
          address: {
            city: temp?.city,
            country: temp?.country[0]?.isoCode,
            line1: temp?.address,
            postal_code: temp?.postal_code,
            state: temp?.state?.name,
          },
          email: temp?.email,
          name: temp?.name,
        },
        card: {
          number: temp?.cardNumber?.split(" ")?.join(""),
          exp_month: temp?.expiryDate.split("/")[0],
          exp_year: temp?.expiryDate.split("/")[1],
          cvc: temp?.cvc,
        },
      });
      if (data?.data?.status === "trialing") {
        try {
          const submitResponse = await axios.post(
            "https://prod-api.cinchy.me/api/v1/subscription",
            {
              stripePaymentMethodId: "",
              subscriptionProductId: params?.productId,
              stripeSubscriptionId: data?.subscriptionId,
              active: true,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${params?.token}`,
              },
            }
          );

          setScreen("success");
        } catch (error) {
          setScreen("authentication");
          console.log(error, "errror 132");
          message.error("Something went wrong!");
        }
      } else if (token && data?.data?.status !== "trialing") {
        try {
          const { paymentIntent, error } = await stripe.confirmCardPayment(
            data?.paymentIntent,
            {
              payment_method: token?.id,
            }
          );
          if (error) {
            // Handle payment error
          } else if (paymentIntent.status === "requires_confirmation") {
          } else if (paymentIntent.status === "succeeded") {
            // Payment was successful
            // You can proceed with order fulfillment or show a success message
          }
        } catch (err) {
          console.log(err, "error 141");
          setScreen("authentication");
          message.error("Something went wrong!");
        }
      }
    } catch (error) {
      console.log(error, "error 146");
      setScreen("authentication");

      message.error(error?.message || "Something went wrong!");
    }
  };
  const handleOtpVerify = async (otp) => {
    try {
      setScreen("verify");
      const response = await axios.post(
        "https://prod-api.cinchy.me/api/v1/subscription/payment-otp-verification",
        // https://staging-api.cinchy.me/api/v1/subscription-product/subscription-token-verification
        {
          priceId: data?.product_price_id,
          email: params?.email,
          otp: otp,
          coupon: discountCode,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${params?.token}`,
          },
        }
      );
      handlePayment(response?.data?.data);
    } catch (error) {
      console.log(error, "error 173");
      setScreen("authentication");
      message.error("Otp is incorrect!");
    }
  };

  const handleCardNumberChange = (e) => {
    let input = e.target.value;
    // Remove non-numeric characters
    input = input.replace(/\D/g, "");
    // Format input with spaces every 4 digits
    if (input.length <= 4) {
      setCardNumber(input);
    } else {
      setCardNumber(
        input
          .match(/.{1,4}/g) // Split the string into 4-character groups
          .join(" ") // Join the groups with a space
      );
    }
  };
  const handleCardExpirationChange = (e) => {
    let input = e.target.value;
    // Remove non-numeric characters
    input = input.replace(/\D/g, "");
    // Format input as MM/YY
    if (input.length <= 2) {
      // For the first 2 characters (month)
      setExpiryDate(input);
    } else if (input.length <= 4) {
      // For 3rd to 4th characters (month and year)
      setExpiryDate(`${input.slice(0, 2)}/${input.slice(2)}`);
    } else {
      // Ignore additional characters
      setExpiryDate(input.slice(0, 4));
    }
  };
  useEffect(() => {
    form.setFieldsValue({ email: params?.email });
    setCountriesList(Country.getAllCountries());
  }, []);
  useEffect(() => {
    setStateList(State.getStatesOfCountry(countryid));
    setstateid(0);
  }, [countryid]);

  return (
    <>
      {!otpSent ? (
        <div className="paymentContainer">
          <Row justify={"space-between"} align={"middle"}>
            <Col>
              <p className="title">Pay with card</p>
            </Col>
            <Col>
              <img src={StripeImg} alt="stripe" />
            </Col>
          </Row>
          <div className="stripeForm">
            <Form onFinish={handleSubmit} form={form}>
              <Form.Item
                name="email"
                label="Email*"
                rules={[
                  {
                    required: true,
                    message: "Please fill your email",
                  },
                  {
                    type: "email",
                    message: "Enter a valid email",
                  },
                ]}
              >
                <Input
                  placeholder="Add Email"
                  type="email"
                  className="inputBox"
                  disabled
                  // value={params?.email}
                />
              </Form.Item>
              <Form.Item
                label="Card Information"
                style={{
                  marginBottom: "0px !important",
                  margin: "0px !important",
                }}
                className="cardNumberInput"
                rules={[
                  {
                    required: true,
                    message: "Please fill card number!",
                  },
                ]}
              >
                <Input
                  placeholder="Card Number"
                  className="cardNumberInputBox"
                  value={cardNumber}
                  onChange={handleCardNumberChange}
                  maxLength={19}
                />
              </Form.Item>
              <Row align={"middle"}>
                <Col span={12}>
                  <Form.Item
                    // name="expiryDate"
                    rules={[
                      {
                        required: true,
                        message: "Please fill expiry date",
                      },
                    ]}
                  >
                    <Input
                      placeholder="MM/YY"
                      className="cardNumberMm"
                      maxLength={5}
                      value={expiryDate}
                      onChange={(e) => handleCardExpirationChange(e)}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="cvc"
                    rules={[
                      {
                        required: true,
                        message: "Please fill cvc!",
                      },
                    ]}
                  >
                    <Input.Password
                      maxLength={4}
                      placeholder="CVC"
                      className="cardNumberCvc"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                name="name"
                label="Name on Card*"
                rules={[
                  {
                    required: true,
                    message: "Please fill name.",
                  },
                ]}
              >
                <Input placeholder="Add Name" className="inputBox" />
              </Form.Item>
              <Row>
                <p className="addressTitle">Billing Address</p>
              </Row>
              <Form.Item label="Country">
                <Select
                  placeholder="Select Country"
                  className="selectBox"
                  onChange={(e) => {
                    setCountryid(e);
                  }}
                  value={countryid}
                >
                  {countriesList.map((item, index) => (
                    <Select.Option key={index} value={item?.isoCode}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="address" label="Address*">
                <Input
                  placeholder="Add Address"
                  className="inputBox"
                  rules={[
                    {
                      required: true,
                      message: "Please fill your address!",
                    },
                  ]}
                />
              </Form.Item>
              <Form.Item label="State">
                <Select
                  placeholder="Select State"
                  className="selectBox"
                  onChange={(e) => {
                    setstateid(e);
                  }}
                  value={stateid}
                >
                  {stateList.map((item, index) => (
                    <Select.Option key={index} value={index}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="city" label="City">
                <Input placeholder="Add City" className="inputBox" />
              </Form.Item>
              <Form.Item name="postal_code" label="Postal Code ">
                <Input placeholder="Add Postal Code" className="inputBox" />
              </Form.Item>
              <div>
                <p className="note">
                  Note: You will be redirected to Stripe for payment. We will
                  not store your credit card information.
                </p>
              </div>
              <Form.Item>
                <Button className="commonBtn" htmlType="submit" loading={loading}>
                  Pay $
                  {data?.default_price -
                    (discountResponse?.percent_off
                      ? (data?.default_price * discountResponse?.percent_off) /
                        100
                      : 0
                    )?.toFixed(2)}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      ) : (
        <Authentication handleSubmit={handleOtpVerify} email={params?.email} />
      )}
    </>
  );
}
export default Payment;
