import { Button } from "antd";
import React from "react";

function Success() {
  return (
    <div>
      <Button className="commonBtn" href="https://willcro.page.link/R6GT">Go to App</Button>
    </div>
  );
}

export default Success;
