import React from "react";
import { Button } from "antd";
function FailedScreen() {
  return (
    <div className="verifyContainer">
      <div>
        <p>Session expired!</p>
        <p className="desc">
          Current session has expired. Please try again to continue.
        </p>
        <Button className="commonBtn">Try Again</Button>
      </div>
    </div>
  );
}

export default FailedScreen;
