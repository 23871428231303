import React from "react";
import loader from "../Assets/loader2.gif";

function LoadingScreen() {
  return (
    // <div>
    <div className="verifyContainer">
      <div>
        <img src={loader} />
      </div>
    </div>
    // </div>
  );
}

export default LoadingScreen;
