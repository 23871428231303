import React, { useState } from "react";
import { Row, Col, Divider, Button, Input, message } from "antd";
import UserIcon from "../Assets/user.svg";
import axios from "axios";
function ReviewAndPay({
  setScreen,
  params,
  data,
  setDiscountCode,
  discountCode,
  discountResponse,
  setDiscountResponse,
}) {
  const checkDiscountCode = async () => {
    setDiscountResponse(null);
    if (discountCode) {
      try {
        if (discountCode) {
          const response = await axios.post(
            "https://prod-api.cinchy.me/api/v1/subscription/discount",
            { discount: discountCode },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${params?.token}`,
              },
            }
          );
          setDiscountResponse(response?.data?.data);
        }
      } catch (error) {
        message.error(
          error?.response?.data?.message?.message ||
            "Something went wrong. Please try again."
        );
      }
    }
  };
  return (
    <div className="reviewAndPay">
      <p className="desc">{data?.description}</p>
      <h3 className="orderTitle">Order Summary</h3>
      {data && (
        <div className="detailBox">
          <Row gutter={16} align={"top"}>
            <Col>
              <img src={UserIcon} alt="user" />
            </Col>
            <Col>
              <p className="subscriptionType">{data?.name}</p>
              <p className="subscriptionRate">
                {data?.default_price -
                  (discountResponse?.percent_off
                    ? (data?.default_price * discountResponse?.percent_off) /
                      100
                    : 0
                  )?.toFixed(2)}
                /{data?.recurringType}
              </p>
            </Col>
          </Row>
          <Divider className="divider" />
          {/* <p className="note">
            Note: The cost of the user is calculated on a prorated basis to the
            next subscription renewal date
          </p> */}
          <Row justify={"space-between"} align={"middle"}>
            <Col>
              <p className="amount">Total Amount</p>
            </Col>
            <Col>
              <p className="amount">
                $
                {data?.default_price -
                  (discountResponse?.percent_off
                    ? (data?.default_price * discountResponse?.percent_off) /
                      100
                    : 0
                  )?.toFixed(2)}
              </p>
            </Col>
          </Row>
        </div>
      )}
      <div className="w-100">
        <div className="detailBox reviewPayCard">
          <p className="Discount_code">Discount code</p>
          <div className="mt-10">
            <Input
              value={discountCode}
              onChange={(e) => setDiscountCode(e?.target?.value)}
              suffix={
                <div
                  className="apply_text cursor-pointer"
                  onClick={() => checkDiscountCode()}
                >
                  Apply
                </div>
              }
              placeholder="Apply discount code"
            />
          </div>
          {discountResponse && (
            <div>
              <p className="discountMsg">
                This code entitles you to a{" "}
                {`${discountResponse?.percent_off}%`} discount on your core
                subscription.
              </p>
            </div>
          )}
        </div>
      </div>
      <p className="desc">
        Note: You will be redirected to Stripe for payment. We will not store
        your credit card information.
      </p>
      <Button className="commonBtn" onClick={() => setScreen("payment")}>
        Pay
      </Button>
    </div>
  );
}

export default ReviewAndPay;
