import React, { useEffect } from "react";
import loader from "../Assets/loader2.gif";

function Verify({ setScreen }) {
  useEffect(() => {
    setTimeout(() => {
      setScreen("success");
    }, 1000000);
  }, []);
  return (
    <div className="verifyContainer">
      <div>
        <img src={loader} />
        <p>Verifying Details...</p>
      </div>
    </div>
  );
}

export default Verify;
