import React, { useState } from "react";
import OtpInput from "react-otp-input";
import { Row, Col, Form, Button } from "antd";
function Authentication({ handleSubmit, email }) {
  const [otp, setOtp] = useState(null);
  const [otpBtnDisabled, setOtpBtnDisabled] = useState(true);
  const [form] = Form.useForm();

  const handleChange = (newValue) => {
    if (newValue.length === 6) {
      setOtpBtnDisabled(false);
    } else {
      setOtpBtnDisabled(true);
    }
    setOtp(newValue);
  };
  const handleFinish = async (values) => {
    handleSubmit(otp);
  };
  return (
    <div className="authenticationContainer">
      <div>
        <p className="desc text-center">
          We have sent you a code on {email}.
        </p>
      </div>
      <Form form={form} onFinish={(values) => handleFinish(values)}>
        <Row>
          <Col span={24}>
            <Form.Item name="otp">
              <OtpInput
                value={otp}
                onChange={handleChange}
                numInputs={6}
                shouldAutoFocus={true}
                isInputNum={true}
                inputStyle="otpInput"
                containerStyle="otpContainer"
                renderSeparator={<span>-</span>}
                renderInput={(props) => <input {...props} />}
                inputType="number"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Form.Item>
              <Button
                className="commonBtn"
                htmlType="submit"
                disabled={otpBtnDisabled}
              >
                Verify
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default Authentication;
