import React, { useState, useEffect } from "react";
import "./styles/index.scss";
import SuccessImg from "./Assets/success.svg";
import ReviewAndPay from "./components/ReviewAndPay";
import Payment from "./components/Payment.js";
import Verify from "./components/Verify";
import Success from "./components/Success";
import BgImage from "./Assets/bg.svg";
import FailedScreen from "./components/FailedScreen";
import { useSearchParams } from "react-router-dom";
import LoadingScreen from "./components/LoadingScreen";
import axios from "axios";
function Subscription() {
  const [screen, setScreen] = useState("loading");
  const [params, setParams] = useState();
  const [searchParams] = useSearchParams();
  const [data, setData] = useState({});
  const [seconds, setSeconds] = useState({});
  const [discountCode, setDiscountCode] = useState(null);
  const [discountResponse, setDiscountResponse] = useState(null);

  useEffect(() => {
    setParams(Object.fromEntries([...searchParams]));
  }, [searchParams]);

  const verifyToken = async (token, sId) => {
    try {
      const response = await axios.post(
        "https://prod-api.cinchy.me/api/v1/subscription-product/subscription-token-verification",
        {
          sId: sId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setData(response?.data?.data?.plan);
      setSeconds(response?.data?.data?.remainingTime);
      setScreen("reviewAndPay");
    } catch (error) {
      setScreen("failed");
    }
  };
  useEffect(() => {
    if (params?.token) {
      verifyToken(params?.token, params?.productId);
    }
  }, [params]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds < 1) {
        clearInterval(interval);
        setScreen("failed");
        return;
      } else {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  return (
    <div className="App">
      <img src={BgImage} className="bgImg" alt="bg" />
      <div className="titleBox">
        <h1>
          {screen === "reviewAndPay"
            ? "Review and Pay"
            : screen === "payment"
            ? "Payment"
            : screen === "authentication"
            ? "Authentication"
            : screen === "success"
            ? "Payment Successful"
            : ""}
        </h1>

        {screen === "success" ? (
          <div className="descContainer">
            <p className="desc description">
              Congratulations you can now go back to the "Cinchy.me" app and
              apply and share your profile.
            </p>
            <div className="imgContainer">
              <img src={SuccessImg} alt="img" />
            </div>
          </div>
        ) : screen !== "failed" && screen !== "loading" ? (
          <p className="timerClass">
            {String(minutes).padStart(2, "0")}:
            {String(remainingSeconds).padStart(2, "0")} minutes left
          </p>
        ) : (
          ""
        )}
      </div>

      <div
        className={`contentContainer ${
          screen === "verify"
            ? "centerContainer"
            : screen === "success"
            ? "successBottomContainer"
            : screen === "loading"
            ? "centerContainer"
            : screen === "failed"
            ? " failedContainer"
            : ""
        }`}
      >
        <div style={{ width: "100%" }}>
          {screen === "loading" && <LoadingScreen />}
          {screen === "failed" && <FailedScreen setScreen={setScreen} />}
          {screen === "reviewAndPay" && (
            <ReviewAndPay
              setScreen={setScreen}
              data={data}
              params={params}
              setDiscountCode={setDiscountCode}
              discountCode={discountCode}
              discountResponse={discountResponse}
              setDiscountResponse={setDiscountResponse}
            />
          )}
          {(screen === "payment" || screen === "authentication") && (
            <Payment
              setScreen={setScreen}
              params={params}
              data={data}
              discountResponse={discountResponse}
              discountCode={discountCode}
            />
          )}
          {screen === "verify" && <Verify setScreen={setScreen} />}
          {screen === "success" && <Success />}
        </div>
      </div>
    </div>
  );
}

export default Subscription;
